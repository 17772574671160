import React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import Scramble from 'react-scramble'
import { websiteConstants } from '../../constants/links'
import useAnalyticsEventTracker from '../../analytics/useAnalyticsEventTracker'
import LazyImage from '../lazy_loading/lazyImage'

const discord = 'logos-icons/icon-social-discord-default.svg'
const twitter = 'logos-icons/icon-social-twitter-default.svg'
const youtube = 'logos-icons/icon-social-youtube-default.svg'
const instagram = 'logos-icons/icon-social-instagram-default.svg'

const Follow = () => {
  const gaEventTracker = useAnalyticsEventTracker('Follow Banner')
  return (
    <>
      <div className="lg:flex lg:items-center text-center justify-around px-6 xl:px-72 2xl:px-96 pt-12 pb-20 lg:pb-36 mx-auto min-w-screen">
        <div className="font-bold text-2xl md:text-4xl 2xl:text-5xl tracking-widest orbitron pb-4 md:pb-0">
          <VisibilitySensor>
            {({ isVisible }) => (
              <Scramble
                autoStart={isVisible}
                text="FOLLOW US"
                steps={[
                  {
                    roll: 6,
                    action: '+',
                    type: 'all',
                  },
                  {
                    action: '-',
                    type: 'forward',
                  },
                ]}
              />
            )}
          </VisibilitySensor>
        </div>
        <div className="flex shrink-0 w-16"></div>
        <div className="lg:hidden flex justify-center">
          <a
            href={websiteConstants.discord}
            className="glow-scale-button"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              style={{ height: '72px', width: '72px' }}
              src={discord}
              alt="discord"
              onClick={() => gaEventTracker('discord')}
            />
          </a>
          <a
            href={websiteConstants.twitter}
            className="glow-scale-button"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              style={{ height: '72px', width: '72px' }}
              src={twitter}
              alt="twitter"
              onClick={() => gaEventTracker('twitter')}
            />
          </a>
          <a
            href={websiteConstants.youTube}
            className="glow-scale-button"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              style={{ height: '72px', width: '72px' }}
              src={youtube}
              alt="youtube"
              onClick={() => gaEventTracker('youtube')}
            />
          </a>
          <a
            href={websiteConstants.instagram}
            className="glow-scale-button"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              style={{ height: '72px', width: '72px' }}
              src={instagram}
              alt="instagram"
              onClick={() => gaEventTracker('instagram')}
            />
          </a>
        </div>
        <div className="hidden lg:flex items-center pr-12">
          <a
            href={websiteConstants.discord}
            className="glow-scale-button w-20"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              className="w-20"
              src={discord}
              alt="discord"
              onClick={() => gaEventTracker('discord')}
            />
          </a>
          <a
            href={websiteConstants.twitter}
            className="glow-scale-button w-20"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              className="w-20"
              src={twitter}
              alt="twitter"
              onClick={() => gaEventTracker('twitter')}
            />
          </a>
          <a
            href={websiteConstants.youTube}
            className="glow-scale-button w-20"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              className="w-20"
              src={youtube}
              alt="youtube"
              onClick={() => gaEventTracker('youtube')}
            />
          </a>
          <a
            href={websiteConstants.instagram}
            className="glow-scale-button w-20"
            target="_blank"
            rel="noreferrer"
          >
            <LazyImage
              className="w-20"
              src={instagram}
              alt="instagram"
              onClick={() => gaEventTracker('instagram')}
            />
          </a>
        </div>
      </div>
    </>
  )
}

export default Follow
