import React, { useRef, useState, useEffect } from 'react'
import { motion, useTransform, useViewportScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import LazyImage from '../lazy_loading/lazyImage'

const ScrollSequence = ({ images }) => {
  const ref = useRef()
  const { scrollYProgress } = useViewportScroll()
  const inputRange = [0.85, 0.9]
  const outputRange = [0, images.length - 1]
  const imageIndex = useTransform(scrollYProgress, inputRange, outputRange, {
    clamp: true,
  })

  const [currentImage, setCurrentImage] = useState(images[0])

  useEffect(() => {
    const unsubscribe = imageIndex.onChange((v) => {
      const index = Math.round(v)
      if (!isNaN(index) && index >= 0) {
        setCurrentImage(images[index % images.length])
      }
    })

    return () => {
      unsubscribe()
    }
  }, [imageIndex, images])

  return (
    <div ref={ref}>
      <motion.div>
        <LazyImage src={currentImage} alt="Scroll sequence" />
      </motion.div>
    </div>
  )
}

ScrollSequence.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ScrollSequence
