import React, { useRef } from 'react'
import { motion, useTransform, useScroll } from 'framer-motion'
import PropTypes from 'prop-types'

const SlideUpText = ({ children, className }) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  })
  const yPosition = useTransform(scrollYProgress, [0, 1], [100, 0])

  return (
    <div className={className} ref={ref}>
      <motion.div className="" style={{ y: yPosition }}>
        {children}
      </motion.div>
    </div>
  )
}

SlideUpText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default SlideUpText
