import React, { useState, useEffect } from 'react'
import Modal from './modal'
import LazyImage from '../../components/lazy_loading/lazyImage'
import CharacterSlideIn from '../../components/animation/characterSlideIn'
import { motion } from 'framer-motion'
import { ButtonLink } from '../../components/links/button-link'
import ImageMarquee from '../../components/animation/characterImageMarquee'
import ScaleImageIn from '../../components/animation/scaleImageIn'
import SlideUpText from '../../components/animation/slideUpText'
import ScrollInImage from '../../components/animation/scrollInImage'
import ConceptArtMarquee from '../../components/animation/conceptArtMarquee'
import ScrollSequence from '../../components/animation/scrollSequence'
import Carousel from '../../components/carousel/carousel'
import FollowBanner from '../../components/follow_banner'

const eternalGuardSilhouetteWide = 'carousel/eternal-guard@3x.png'
const eternalGuardSilhouetteTall = 'legendari/eternal-guard-silhouette-tall.png'
const legendariLogoMain = 'legendari/mtl_alt_color-rev@2x.png'
const legendariLogo = 'legendari/mtl_logotype_white@2x.png'
const meldmaster = 'legendari/meldmaster-prototype-tall.png'
const cyberius = 'legendari/cyberius-tall.png'
const spyte = 'legendari/spyte-prototype-tall.png'
const quikJak = 'legendari/quik-jak-prototype-tall.png'
const eternalGuard = 'legendari/eternal-guard-prototype-tall.png'
const voidWalker = 'legendari/void-walker-prototype-tall.png'
const avatarUnlocked = 'legendari/avatar-unlocked.png'
const interchangeable = 'legendari/interchangeable.gif'
const articulation = 'legendari/articulation.png'
const nexusTower = 'legendari/nexus-tower.png'
const quikjakComic = 'legendari/quik-jak-comic.png'
const sliderImages = [spyte, meldmaster, voidWalker, quikJak, eternalGuard]

const Legendari = () => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true)
    }, 9000)

    return () => clearTimeout(timer)
  }, [])

  const closeModal = () => {
    setShowModal(false)
  }

  const [textWidth, setTextWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setTextWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const tickerVariants = {
    animate: {
      x: [0, -2500],
      transition: {
        x: {
          repeat: Infinity,
          duration: 30,
          ease: 'linear',
        },
      },
    },
  }

  return (
    <>
      {/* Popup */}
      {showModal && <Modal onClose={closeModal} />}

      {/* Main banner */}
      <div className="welcome-bg pt-12 lg:pt-24" id="legendari">
        <div className="relative">
          <div className="hidden lg:block">
            <LazyImage
              src={eternalGuardSilhouetteWide}
              alt="action figure prototype"
              className="w-full"
            />
          </div>
          <div className="lg:hidden">
            <LazyImage
              src={eternalGuardSilhouetteTall}
              alt="action figure prototype"
              className="w-full"
            />
          </div>
          <div className="absolute inset-0 flex items-top justify-center">
            <div className="pt-6 w-1/2 lg:w-1/4 text-center pb-36">
              <LazyImage src={legendariLogoMain} className="pt-5" />
            </div>
          </div>
        </div>

        {/* Scrolling text marquee */}
        <div className="overflow-hidden fade-container">
          <motion.div
            className="flex text-6xl lg:text-10xl orbitron font-bold py-12 whitespace-nowrap"
            variants={tickerVariants}
            animate="animate"
          >
            <div style={{ minWidth: textWidth, paddingRight: '50px' }}>
              PREMIUM ACTION FIGURES PREMIUM ACTION FIGURES PREMIUM ACTION
              FIGURES PREMIUM ACTION FIGURES PREMIUM ACTION FIGURES
            </div>
          </motion.div>
        </div>

        {/* Hero section 1 */}
        <div className="flex flex-col lg:flex-row items-center justify-center lg:py-12">
          <div className="lg:w-1/2 px-6 lg:px-10 text-center items-center justify-center hidden lg:block">
            <SlideUpText className="text-4xl orbitron font-bold pb-3">
              RISE OF THE
            </SlideUpText>
            <SlideUpText className="text-4xl orbitron font-bold">
              <LazyImage src={legendariLogo} className="" />
            </SlideUpText>
            <p className="lg:text-4xl font-thin pt-12">
              The first edition of a new line of collectibles based on a rich
              universe of characters, with lore crafted by comic writing legend
              and creator of the original Marvel’s “Guardians of the Galaxy”
              Andy Lanning and drawn by veteran Anthony Williams.
            </p>
            <SlideUpText className="text-4xl font-bold pb-6">
              <div className="pt-12">
                <ButtonLink
                  href={`https://www.kickstarter.com/projects/metatravelers/legendari-action-figures`}
                  text={'BE THE FIRST TO COLLECT'}
                  target="_self"
                  invert={true}
                />
              </div>
            </SlideUpText>
          </div>
          <div className="lg:w-1/3 px-6">
            <ScaleImageIn
              src={cyberius}
              alt="action figure prototype"
              className="rounded-xl lg:rounded-3xl"
            />
          </div>
        </div>

        <div className="relative">
          <div className="lg:hidden p-6 text-center">
            <SlideUpText className="text-3xl orbitron font-bold pb-6">
              RISE OF THE LEGENDARI
            </SlideUpText>

            <p className="text-xl font-thin">
              The first edition of a new line of collectibles based on a rich
              universe of characters, with lore crafted by comic writing legend
              and creator of the original Marvel’s “Guardians of the Galaxy”
              Andy Lanning and drawn by veteran Anthony Williams.
            </p>
            <div className="py-6">
              <ButtonLink
                href={`https://www.kickstarter.com/projects/metatravelers/legendari-action-figures`}
                text={'BE THE FIRST TO COLLECT'}
                target="_self"
                invert={true}
              />
            </div>
          </div>
        </div>

        <div className="lg:py-24">
          <Carousel images={sliderImages} />
        </div>

        <div className="text-center p-6">
          <p className="text-base lg:text-3xl font-thin py-6 lg:px-24">
            High-quality action figures engineered by
            <b> Four Horsemen Studios</b>, a renowned toy design firm that has
            worked with the world&apos;s largest toy companies on some of the
            most well-known, fan-favorite properties. Beginning in 2015, the
            company began producing their <b>Mythic Legions</b> action figure
            line featuring highly detailed sculpting and interchangeable parts.
            The Legions line became a global phenomenon, and the studio&apos;s
            follow up lines of <b>Cosmic Legions</b> and <b>Figura Obscura </b>
            solidified Four Horsemen Studios&apos; standing as one of the most
            exciting and innovative action figure creators in the industry
            today. Strategic consulting for marketing and distribution by
            <b> Joe Veteri</b> of <b>Veteri Productions</b>.
          </p>
        </div>
        <div className="p-6 lg:py-12 lg:flex lg:justify-center lg:items-center">
          <div className="">
            <ScrollInImage
              src={articulation}
              alt="action figure prototype"
              className="rounded-xl"
            />
          </div>
          <div className="text-center text-base lg:text-2xl space-y-3 p-6 lg:p-12">
            <SlideUpText className="text-4xl lg:text-5xl text-center py-6 orbitron font-bold">
              LEGENDARI FEATURES
            </SlideUpText>
            <div className="">7-inch tall figures</div>
            <div> Soft touch and hard textured materials</div>
            <div className="">Up to 29 points of articulation</div>
            <div className="">Interchangeable parts</div>
            <div className="">Engineered by Four Horsemen Studios</div>
          </div>
        </div>

        <div className="p-6 lg:py-12 lg:flex lg:justify-center lg:items-center">
          <div className="text-center text-base lg:text-2xl space-y-3 p-6 lg:p-12 hidden lg:block">
            <SlideUpText className="text-4xl lg:text-5xl text-center py-6 orbitron font-bold">
              3D STRETCH GOALS
            </SlideUpText>
            <div className="">
              Connecting physical collectibles with digital experiences.
            </div>
            <div>
              Access 3D digital files that extend the physical collectible in
              unique ways.
            </div>
            <div className="">Up to 29 points of articulation</div>
            <div className="">Interchangeable parts</div>
            <div className="">Engineered by Four Horsemen Studios</div>
          </div>
          <div className="">
            <ScaleImageIn
              className="rounded-2xl"
              src={avatarUnlocked}
              alt="digital avatars"
            />
          </div>
          <div className="text-center text-base lg:text-2xl space-y-3 p-6 lg:p-12 lg:hidden">
            <SlideUpText className="text-4xl lg:text-5xl text-center py-6 orbitron font-bold">
              3D STRETCH GOALS
            </SlideUpText>
            <div className="">
              Connecting physical collectibles with digital experiences.
            </div>
            <div>
              Access 3D digital files that extend the physical collectible in
              unique ways.
            </div>
            <div className="">Up to 29 points of articulation</div>
            <div className="">Interchangeable parts</div>
            <div className="">Engineered by Four Horsemen Studios</div>
          </div>
        </div>

        <div className="py-12">
          <ImageMarquee />
        </div>

        <div className="text-4xl text-center pb-6 orbitron font-bold lg:hidden">
          <div className="text-8xl">
            14 <p />
          </div>
          ALL-NEW <p /> CHARACTERS
        </div>

        <div className="orbitron font-bold hidden lg:block">
          <div className="flex items-center text-center justify-center">
            <div className="text-10xl flex px-3">14</div>
            <div className="flex flex-col justify-center text-left text-7xl px-3">
              <div>ALL-NEW</div>
              <div>CHARACTERS</div>
            </div>
          </div>
        </div>

        <div className="">
          <CharacterSlideIn />
        </div>
        <div className="text-center text-xl space-y-3 px-6 py-6 lg:py-12">
          <SlideUpText className="text-4xl lg:text-5xl text-center py-6 orbitron font-bold">
            ...AND MORE!!!
          </SlideUpText>
        </div>

        <div className="text-center text-xl lg:hidden space-y-3 px-6 py-6 lg:py-12">
          <div className="text-3xl text-center pt-6 orbitron font-bold">
            INTERCHANGEABLE PARTS
          </div>
          <LazyImage src={interchangeable} alt="action figure prototype" />
          <div className="uppercase orbitron font-bold py-6">
            Customize your collecting experience across the entire line of
            action figures!
          </div>
        </div>
        <div className="py-6 lg:py-24">
          <ConceptArtMarquee />
        </div>

        <div className="flex flex-col items-center justify-center text-center text-xl space-y-3 px-6 py-12 lg:py-12">
          <div className="text-3xl lg:text-5xl text-center pt-6 orbitron font-bold">
            EXPLORE NEW WORLDS
          </div>
          <div className="py-6 flex justify-center">
            <div className="lg:w-2/3">
              <ScaleImageIn
                src={nexusTower}
                alt="inside the nexus tower"
                className="rounded-2xl"
              />
            </div>
          </div>
          <div className="uppercase orbitron font-bold lg:text-3xl lg:w-2/3">
            Be your own action figure in an expanding universe
          </div>
        </div>

        <ScrollSequence
          images={[
            'legendari/eg-wings-sequence/eg-wings-sequence-1.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-2.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-3.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-4.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-5.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-6.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-7.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-8.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-9.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-10.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-11.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-12.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-13.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-14.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-15.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-16.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-17.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-18.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-19.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-20.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-21.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-22.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-23.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-24.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-25.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-26.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-27.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-28.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-29.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-30.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-31.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-32.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-33.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-34.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-35.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-36.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-37.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-38.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-39.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-40.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-41.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-42.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-43.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-44.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-45.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-46.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-47.png',
            'legendari/eg-wings-sequence/eg-wings-sequence-48.png',
          ]}
        />

        <div className="p-6 lg:py-12 flex justify-center items-center">
          <div className="flex justify-center items-center">
            <ScrollInImage
              src={quikjakComic}
              alt="action figure prototype"
              className="rounded-xl"
            />
          </div>
          <div className="text-center text-xl space-y-3 px-6 py-6 lg:py-12 z-10">
            <SlideUpText className="text-4xl lg:text-5xl text-center py-6 orbitron font-bold">
              LEGENDARI Action Figures
            </SlideUpText>
            <div className="lg:text-2xl font-thin text-center ">
              Your gateway to an epic adventure - crafted by the masters,
              designed for the chosen. This is not just a collectible, it&apos;s
              a legacy. Become a part of the story, traveler.
            </div>
            <div className="py-6 lg:pt-12 flex items-center justify-center">
              <ButtonLink
                href={`https://www.kickstarter.com/projects/metatravelers/legendari-action-figures`}
                text={'BECOME LEGENDARI'}
                target="_self"
                invert={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className=" md:pt-16">
          <FollowBanner />
        </div>
      </div>
    </>
  )
}

export default Legendari
