import React, { useState, useEffect } from 'react'

const ScrollProgressBar = () => {
  const [scrollProgress, setScrollProgress] = useState(0)

  const updateScrollProgress = () => {
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight - windowHeight
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    const scrollPercent = (scrollTop / documentHeight) * 100
    setScrollProgress(scrollPercent)
  }

  useEffect(() => {
    window.addEventListener('scroll', updateScrollProgress)
    return () => window.removeEventListener('scroll', updateScrollProgress)
  }, [])

  return (
    <div className="fixed left-0 w-full h-1 border-color bg-opacity-50">
      <div
        className="h-full bg-world-card-border"
        style={{ width: `${scrollProgress}%` }}
      ></div>
    </div>
  )
}

export default ScrollProgressBar
