import { addDoc, collection } from 'firebase/firestore'
import React, { useState } from 'react'
import { firestore } from '../../firebase.js'
import PropTypes from 'prop-types'

const Modal = ({ onClose }) => {
  const [email, setEmail] = useState('')
  const [showThankYou, setShowThankYou] = useState(false)

  const handleEmailSubmit = async (e) => {
    e.preventDefault()

    if (!email) return

    try {
      await addDoc(collection(firestore, 'subscribers'), { email })
      setShowThankYou(true)
      setTimeout(() => {
        onClose()
        setEmail('')
        setShowThankYou(false)
      }, 3000)
    } catch (error) {
      console.error('Error storing email', error)
    }
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4 lg:px-0">
      <div className="bg-dark-purple rounded-md p-8 space-y-4">
        {showThankYou ? (
          <div>
            <h2 className="text-2xl font-bold tracking-wide orbitron">
              Thank you!
            </h2>
            <p className="font-thin text-xl pt-6">
              You have successfully subscribed to our newsletter.
            </p>
          </div>
        ) : (
          <>
            <div className="flex justify-between items-center">
              <h2 className="text-2xl font-bold tracking-wide orbitron">
                Subscribe to our newsletter
              </h2>
              <button
                className="m-2 bg-white text-gray-800 font-bold text-lg rounded-full w-6 h-6 flex items-center justify-center"
                onClick={onClose}
              >
                &times;
              </button>
            </div>
            <p className="font-thin text-xl">
              Stay up to date on our Kickstarter launch and receive exclusive
              offers.
            </p>

            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                className="w-full text-black-purple text-xl px-6 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className="w-full mt-4 orbitron bg-black-purple text-white text-xl font-bold py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                Subscribe
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

Modal.propTypes = {
  onClose: PropTypes.func,
}

export default Modal
