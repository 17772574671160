import React, { useRef } from 'react'
import { motion, useTransform, useScroll } from 'framer-motion'
import PropTypes from 'prop-types'
import LazyImage from '../lazy_loading/lazyImage'

const ScrollInImage = ({ src, alt, className, ...props }) => {
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start end', 'end end'],
  })
  const opacity = useTransform(scrollYProgress, [0.5, 1], [0.5, 1])
  const xPosition = useTransform(scrollYProgress, [0, 1], [-200, 0])
  const rotation = useTransform(scrollYProgress, [0, 1], [350, 360])

  return (
    <div className="" ref={ref}>
      <motion.div
        className=""
        style={{ opacity, x: xPosition, rotate: rotation }}
      >
        <LazyImage src={src} alt={alt} className={className} {...props} />
      </motion.div>
    </div>
  )
}

ScrollInImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default ScrollInImage
