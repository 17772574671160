import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { motion, useAnimation } from 'framer-motion'
import LazyImage from '../lazy_loading/lazyImage'

const images = [
  'ks-charsheets/KS-CharSheet_MeldMaster.png',
  'ks-charsheets/KS-CharSheet_SpyteUsurper.png',
  'ks-charsheets/KS-CharSheet_VoidWalker.png',
  'ks-charsheets/KS-CharSheet_QuikJak.png',
  'ks-charsheets/KS-CharSheet_PaleKing.png',
  'ks-charsheets/KS-CharSheet_Arcturus.png',
  'ks-charsheets/KS-CharSheet_KaelDarkhelm.png',
  'ks-charsheets/KS-CharSheet_CyberiusKaine.png',
  'ks-charsheets/KS-CharSheet_EtGuard1.png',
  'ks-charsheets/KS-CharSheet_EtGuard2.png',
]

const variants = {
  hidden: { opacity: 0, x: '-100%' },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      type: 'spring',
      stiffness: 75,
      damping: 20,
    },
  },
}

const variants2 = {
  hidden: { opacity: 0, x: '100%' },
  visible: {
    opacity: 1,
    x: '0%',
    transition: {
      type: 'spring',
      stiffness: 75,
      damping: 20,
    },
  },
}

const ImageRow = ({ images }) => {
  const controls = useAnimation()
  const [ref, setRef] = useState(null)
  const [inView, setInView] = useState(false)
  const [hasAnimated, setHasAnimated] = useState(false)

  useEffect(() => {
    if (!ref) return

    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting)
      },
      { threshold: 0.5 }
    )

    observer.observe(ref)

    return () => {
      observer.unobserve(ref)
    }
  }, [ref])

  useEffect(() => {
    if (inView && !hasAnimated) {
      controls.start('visible')
      setHasAnimated(true)
    } else if (!inView && !hasAnimated) {
      controls.start('hidden')
    }
  }, [controls, inView, hasAnimated])

  return (
    <div ref={setRef} className="flex flex-wrap overflow-hidden lg:px-16">
      {images.map((image, index) => (
        <motion.div
          key={index}
          className="w-full md:w-1/2 p-12"
          initial="hidden"
          animate={controls}
          variants={index % 2 === 0 ? variants : variants2}
        >
          <LazyImage src={image} className="w-full h-auto" />
        </motion.div>
      ))}
    </div>
  )
}

ImageRow.propTypes = {
  images: PropTypes.array.isRequired,
}

const CharacterSlideIn = () => {
  return (
    <>
      {Array.from({ length: images.length / 2 }).map((_, i) => (
        <ImageRow key={i} images={images.slice(i * 2, i * 2 + 2)} />
      ))}
    </>
  )
}

export default CharacterSlideIn
