import { motion } from 'framer-motion'
import React from 'react'
import LazyImage from '../lazy_loading/lazyImage'

const images = [
  'legendari/conceptart-arcturus.jpg',
  'legendari/conceptart-cyberius.jpg',
  'legendari/conceptart-etguard.jpg',
  'legendari/conceptart-kael.jpg',
  'legendari/conceptart-meldmaster.jpg',
  'legendari/conceptart-nexzus.jpg',
  'legendari/conceptart-paleking.jpg',
  'legendari/conceptart-quikjack.jpg',
]

const ConceptArtMarquee = () => {
  return (
    <div className="flex overflow-hidden h-[400px] lg:h-[600px]">
      {images.map((img, i) => (
        <motion.div
          key={i}
          className="flex-shrink-0 h-full mr-[-1px]"
          style={{ width: 'auto' }}
          initial={{ x: i * 100 + '%' }}
          animate={{
            x: [0, -3000],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: 30,
                ease: 'linear',
              },
            },
          }}
          transition={{
            repeat: Infinity,
            duration: 5,
            ease: 'linear',
          }}
        >
          <LazyImage src={img} alt="" className="h-full object-cover" />
        </motion.div>
      ))}
    </div>
  )
}

export default ConceptArtMarquee
