import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const useHashScroll = () => {
  const location = useLocation()

  const scrollToHash = () => {
    const { hash } = window.location
    if (hash) {
      setTimeout(() => {
        const target = document.querySelector(hash)
        if (target) {
          target.scrollIntoView({ behavior: 'smooth' })
        }
      }, 300) // Adjust the timeout value based on your animation duration
    }
  }

  useEffect(() => {
    if (location.pathname || location.hash) {
      scrollToHash()
    }
  }, [location.pathname, location.hash])
}

export default useHashScroll
