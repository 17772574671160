import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyD4a9MCQlKdtZ5k_1KqPCKVBFyavUEpdw4',
  authDomain: 'metatravelers-657f6.firebaseapp.com',
  projectId: 'metatravelers-657f6',
  storageBucket: 'metatravelers-657f6.appspot.com',
  messagingSenderId: '212729426526',
  appId: '1:212729426526:web:68d5c4c0ff25b04365bacf',
  measurementId: 'G-QBKDW1639V',
}

const app = initializeApp(firebaseConfig)

const firestore = getFirestore(app)

export { firestore }
