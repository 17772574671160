export const websiteConstants = {
  youTube: 'https://www.youtube.com/c/MetaTravelers',
  miraPrime: 'https://leapn.life/metatravelers/mira-prime',
  discord: 'https://discord.gg/MetaTravelers',
  twitter: 'http://twitter.com/metatravelers',
  instagram: 'https://instagram.com/metatravelers',
  nibiru: 'https://opensea.io/collection/metatravelers',
  genesisStone: 'https://opensea.io/collection/metatravelers-genesis-stone',
  nibiruComics: 'https://opensea.io/collection/metatravelers-nibiru-comics',
  viewer: 'https://view.metatravelers.io/',
  leapN: 'https://leapn.life/',
  decentraland: 'https://play.decentraland.org/?position=-25%2C-39',
  ideasAndInks: 'https://www.ideasandinks.com/',
  polygonStudios: 'https://polygonstudios.com/',
  netvrk: 'https://www.netvrk.co/',
  webaverse: 'https://webaverse.com/',
  zoodao: 'https://zoodao.com/',
  metaOps: 'https://metaopsgaming.com/',
  email: 'mailto:metatravelers@nextdimensionstudios.com',
  typeform: 'https://forms.gle/VrMPEcdRhjismJZ1A',
  kickstarter: 'https://www.metatravelers.io/legendari',
  fourHorsemen: 'https://www.sourcehorsemen.com/',
}
