import { motion } from 'framer-motion'
import React from 'react'
import LazyImage from '../lazy_loading/lazyImage'

const images = [
  'legendari/arcturus-concept.png',
  'legendari/battler-concept.png',
  'legendari/cyberius-concept.png',
  'legendari/darkhelm-concept.png',
  'legendari/arcturus-concept.png',
  'legendari/battler-concept.png',
  'legendari/cyberius-concept.png',
  'legendari/darkhelm-concept.png',
  'legendari/arcturus-concept.png',
  'legendari/battler-concept.png',
  'legendari/cyberius-concept.png',
  'legendari/darkhelm-concept.png',
]

const ImageMarquee = () => {
  return (
    <div className="flex overflow-hidden h-[300px] lg:h-[600px]">
      {images.map((img, i) => (
        <motion.div
          key={i}
          className="w-full h-full aspect-[1/1]"
          initial={{ x: i * 100 + '%' }}
          animate={{
            x: [0, -3000],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: 30,
                ease: 'linear',
              },
            },
          }}
          transition={{
            repeat: Infinity,
            duration: 5,
            ease: 'linear',
          }}
        >
          <LazyImage src={img} alt="" className="object-cover" />
        </motion.div>
      ))}
    </div>
  )
}

export default ImageMarquee
