import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga4'

export class ButtonLink extends Component {
  render() {
    let color, font
    if (this.props.invert === true)
      color = 'border-color button-color-invert text-black-purple'
    else {
      color = 'text-white bg-color button-color border-color'
    }

    if (this.props.text.length > 10) {
      font =
        'px-4 md:x-6 xl:px-8 py-2 xl:py-3 text-xs md:text-sm lg:text-base xl:text-xl font-bold tracking-wide no-underline border rounded-full whitespace-normal md:whitespace-nowrap'
    } else {
      font =
        'px-4 md:x-6 xl:px-8 py-2 xl:py-3 text-xs md:text-sm lg:text-base xl:text-xl font-bold md:tracking-wide no-underline border rounded-full whitespace-nowrap'
    }
    return (
      <div className="z-10">
        <Link
          to={this.props.href}
          className={font + ' ' + color}
          target={this.props.target}
          onClick={() => ReactGA.event('Button', 'Click', this.props.text)}
        >
          {this.props.text}
        </Link>
      </div>
    )
  }
}

ButtonLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  target: PropTypes.string,
  invert: PropTypes.bool,
}
