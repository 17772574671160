import React from 'react'
import { Link } from 'react-router-dom'
import { ButtonLink } from '../links/button-link'
// import { websiteConstants } from '../../constants/links'
// import PopoverDropdown from './components/popoverDropdown'
import ScrollProgressBar from './components/scrollProgressBar'
import LazyImage from '../lazy_loading/lazyImage'

const mtLogo = 'legendari/mtl_alt-horiz_color-rev@2x.png'
// const discordLogo = 'logos-icons/icon-social-discord-default.svg'
// const menuIcon = 'logos-icons/icon-menu-hamburger-default.svg'
// const closeIcon = 'logos-icons/icon-menu-close-default.svg'

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

const Navbar = () => {
  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-50 bg-black-purple">
        <div className="px-6 py-4 lg:px-20 border-b border-color">
          <div className="">
            <div className="flex lg:px-20 items-center justify-between">
              <Link to="/#home" onClick={scrollToTop}>
                <LazyImage className="w-1/3" src={mtLogo} alt="Legendari" />
              </Link>
              <div className="flex items-center py-2 sm:py-4 lg:space-x-4 flex-shrink-0 pr-2 sm:pr-4 lg:pr-0">
                <div className="md:block hidden">
                  <ButtonLink
                    href={`https://www.kickstarter.com/projects/metatravelers/legendari-action-figures`}
                    text="See Our Kickstarter"
                    target="_self"
                    invert={true}
                  />
                </div>
                {/* <a
                  href={websiteConstants.discord}
                  target="_blank"
                  rel="noreferrer"
                  className="flex-shrink-0 min-w-12 lg:min-w-16"
                >
                  <LazyImage
                    className="hidden md:block glow-scale-button w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16"
                    src={discordLogo}
                    alt="Legendari Discord"
                  />
                </a> */}
                {/* <div className="flex-shrink-0 items-center">
                  <PopoverDropdown
                    trigger={
                      <LazyImage
                        src={menuIcon}
                        alt="Menu"
                        className="w-6 h-6 md:w-10 md:h-10 lg:w-14 lg:h-14"
                      />
                    }
                    closeTrigger={
                      <LazyImage
                        src={closeIcon}
                        alt="Close"
                        className="w-6 h-6 md:w-10 md:h-10 lg:w-14 lg:h-14"
                      />
                    }
                  ></PopoverDropdown>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <ScrollProgressBar />
      </div>
    </>
  )
}

export default Navbar
