import React, { useEffect } from 'react'
import Navbar from './components/navbar/index.js'
// import Footer from './components/footer/index.js'
import {
  BrowserRouter as Router,
  // Routes,
  // Route,
  useLocation,
} from 'react-router-dom'
// import Home from './pages/home/index.js'
// import Collections from './pages/collections/index.js'
// import Partners from './pages/partners/index.js'
// import AvatarEcosystem from './pages/avatar_ecosystem/index.js'
// import Faq from './pages/faq/index.js'
// import Privacy from './pages/privacy/index.js'
// import FourOhFour from './pages/404/_404.js'
import ScrollToTop from './ScrollToTop.js'
import ReactGA from 'react-ga4'
import Legendari from './pages/legendari/index.js'
import UseHashScroll from './hooks/useHashScroll.js'

const TRACKING_ID = 'G-JWXFJR18YF'
ReactGA.initialize(TRACKING_ID)

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  )
}

function AppContent() {
  const location = useLocation()

  useEffect(() => {
    ReactGA.send('page_view', {
      page_location: window.location.href,
      page_path: location.pathname,
      page_title: document.title,
    })
  }, [location])

  return (
    <>
      <UseHashScroll />
      <ScrollToTop />
      <Navbar />
      <div className="site-body">
        <Legendari />
        {/* <Footer /> */}
      </div>
    </>
  )
}

export default App
