// LazyImage.js
import React from 'react'
import PropTypes from 'prop-types'

const CDN_BASE_URL = 'https://diclmw9aok8or.cloudfront.net'

const LazyImage = ({ src, alt, className, ...props }) => {
  const fullSrc = `${CDN_BASE_URL}/${src}`
  return (
    <div className="w-full h-full">
      <img
        src={fullSrc}
        alt={alt || 'Lazy Loaded Image'}
        className={className}
        {...props}
      />
    </div>
  )
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default LazyImage
