import React from 'react'
import Flicking, { ViewportSlot } from '@egjs/react-flicking'
import { Perspective, AutoPlay } from '@egjs/flicking-plugins'
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/flicking-plugins/dist/pagination.css'

import '@egjs/react-flicking/dist/flicking.css'
// Or, if you have to support IE9
import '@egjs/react-flicking/dist/flicking-inline.css'
import PropTypes from 'prop-types'
import LazyImage from '../lazy_loading/lazyImage'

const Carousel = ({ images }) => {
  const _plugins = [
    new Perspective({ rotate: 0.0, scale: 0.2 }),
    new AutoPlay({ duration: 1500, direction: 'NEXT', stopOnHover: false }),
  ]

  return (
    <div className="">
      <Flicking
        align="center"
        circular={true}
        onMoveEnd={(e) => {
          console.log(e)
        }}
        plugins={_plugins}
      >
        {images.map((image, index) => (
          <div className="panel w-2/3 lg:w-1/3" key={index}>
            <LazyImage src={image} alt="carousel" className="rounded-xl" />
          </div>
        ))}
        <ViewportSlot>
          <span className="flicking-pagination"></span>
        </ViewportSlot>
      </Flicking>
    </div>
  )
}

Carousel.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Carousel
